export const isMacOS = ():boolean => {
  if(navigator !== undefined) {
    if ('userAgent' in navigator) {
      return navigator.userAgent.toLowerCase().indexOf('mac os') >= -1;
    }
  }
  return false;
}

export const baseShortcutKey = (): string => {
  return isMacOS() ? '⌥' : 'alt';
}

export const generateShortcutTooltip = (keyCombination: string[]): string => {
  if (keyCombination.length === 0) {
    throw new Error('Shortcuts must have a key combination');
  }

  return 'Shortcut: ' + baseShortcutKey() + ' + ' + keyCombination.join(' + ').toUpperCase();
}
